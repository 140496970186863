// 批量导入图标
const imageContext = require.context('@/views/futureService/aPicture/img', false, /map_icon\d\d\.png$/);
const iconMap = {};
imageContext.keys().forEach(key => {
  iconMap[key.slice(2, -4)] = imageContext(key);
});

// 定位点集合
const pointLayerList = [
  {
    name: '井亭家园公交站南',
    id: '3bba23bbe28e4a1da9ea5dec720b3e88',
    uiName: '公交站',
    left: '36%',
    top: '5%',
    imgIcon: iconMap.map_icon11,
    textBgColor: '#E68246'
  },
  {
    name: '井亭家园公交站北',
    id: '7bdfe2729d044c93b8d6007172e9f98c',
    uiName: '公交站',
    left: '55%',
    top: '2.8%',
    imgIcon: iconMap.map_icon11,
    textBgColor: '#E68246'
  },
  {
    name: '井亭家园物业',
    id: '',
    uiName: '井亭家园物业',
    left: '50%',
    top: '11.5%',
    imgIcon: iconMap.map_icon01,
    textBgColor: '#903EDE'
  },
  {
    name: '集仕芯谷',
    id: '684714239aaf455190f753d6a165c53a',
    uiName: '集仕芯谷',
    left: '23.8%',
    top: '25%',
    imgIcon: iconMap.map_icon02,
    textBgColor: '#903EDE'
  },
  {
    name: '室外运动场',
    id: '5f4dd07dce1e49b098475fa14a098774',
    uiName: '室外运动区',
    left: '48.5%',
    top: '22%',
    imgIcon: iconMap.map_icon04,
    textBgColor: '#53D088'
  },
  {
    name: '文化家园',
    id: 'd6c2e567bc584c4ca69468a4ba78b0fb',
    uiName: '文化家园',
    left: '55.6%',
    top: '21.4%',
    imgIcon: iconMap.map_icon08,
    textBgColor: '#E8A44A'
  },
  {
    name: '集士港中心幼儿园',
    id: '',
    uiName: '集士港中心幼儿园',
    left: '62.5%',
    top: '26%',
    imgIcon: iconMap.map_icon09,
    textBgColor: '#4C8CD2'
  },
  {
    name: '海曙第三人民医院',
    id: '2c797fa80a8c4a97b58e1e48cf832cf4',
    uiName: '海曙第三医院',
    left: '72%',
    top: '15.5%',
    imgIcon: iconMap.map_icon10,
    textBgColor: '#53D088'
  },
  {
    name: '奥特莱斯',
    id: '',
    uiName: '衫井奥特莱斯',
    left: '92.2%',
    top: '34.2%',
    imgIcon: iconMap.map_icon02,
    textBgColor: '#903EDE'
  },
  {
    name: '文化公园',
    id: '3ae5348cefae41e99839ec4f1a937ed7',
    uiName: '文化公园',
    left: '36%',
    top: '37.8%',
    imgIcon: iconMap.map_icon03,
    textBgColor: '#E8A44A'
  },
  {
    name: '议事长廊',
    id: '37e54effd8e54ba2924a867fed5990ee',
    uiName: '井亭议事廊',
    left: '41%',
    top: '38.5%',
    imgIcon: iconMap.map_icon06,
    textBgColor: '#E23E45'
  },
  {
    name: '党群服务中心',
    id: '4676fcd9aa434b7482fdf823d5f365d7',
    uiName: '党群服务中心',
    left: '53%',
    top: '41%',
    imgIcon: iconMap.map_icon05,
    textBgColor: '#E23E45'
  },
  {
    name: '慢步跑道',
    id: 'f32c205e2d6a44a98cb834fef002d7ca',
    uiName: '漫步绿道',
    left: '54%',
    top: '50.5%',
    imgIcon: iconMap.map_icon04,
    textBgColor: '#53D088'
  },
  {
    name: '万科金色城市幼儿园',
    id: '',
    uiName: '万科金色城市幼儿园',
    left: '31%',
    top: '61%',
    imgIcon: iconMap.map_icon09,
    textBgColor: '#4C8CD2'
  },
  {
    name: '最美上学路',
    id: 'dcd59b18d58344a1bfedd2fe12f4349c',
    uiName: '最美上学路',
    left: '44.5%',
    top: '57.6%',
    imgIcon: iconMap.map_icon07,
    textBgColor: '#4C8CD2'
  },
  {
    name: '万科金色城市公交站南',
    id: '9a75b685ac5749008280e0ada0ea0433',
    uiName: '公交站',
    left: '38%',
    top: '77%',
    imgIcon: iconMap.map_icon11,
    textBgColor: '#E68246',
  },
  {
    name: '万科物业',
    id: '9961ac78f8dc4d49a1e4c8ebd0e785b6',
    uiName: '万科金色城市物业',
    left: '42%',
    top: '73%',
    imgIcon: iconMap.map_icon01,
    textBgColor: '#903EDE',
    zIndex: 1
  },
  {
    name: '万科金色城市公交站北',
    id: '33ce167b485f4f3e943d32edd844ec8a',
    uiName: '公交站',
    left: '35%',
    top: '75%',
    imgIcon: iconMap.map_icon11,
    textBgColor: '#E68246',
    zIndex: 1
  },
  {
    name: '樱花里',
    id: 'dcc9d6a671c44d429d2129b580471401',
    uiName: '樱花里',
    left: '62%',
    top: '83%',
    imgIcon: iconMap.map_icon02,
    textBgColor: '#903EDE'
  },
];

export { iconMap, pointLayerList };
