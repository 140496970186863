<template>
  <div class="aPicture">
    <img class="map-bg" src="./img/mapbg.png">
    <div
      class="point-layer"
      v-for="(item, index) in pointLayerList"
      :key="'dttb' + index"
      :style="{left: item.left, top: item.top, zIndex: item.zIndex || 2}"
      @click="pointLayerOnClick(item, index)"
    >
      <img class="point-icon" :src="item.imgIcon">
      <div class="text" :style="{backgroundColor: item.textBgColor}">{{ item.name }}</div>
    </div>
  </div>
</template>

<script>
import { pointLayerList } from './map.js';

export default {
  name: 'aPicture',
  data() {
    return {
      pointLayerList: pointLayerList
    };
  },
  computed: {},
  created() {
  },
  methods: {
    pointLayerOnClick(item) {
      if (item.id) {
        window.open(`https://jt.zhuneng.cn/bigscreen/#/vrshareview?id=${item.id}`, '_blank');
      } else {
        this.$toast('抓紧拍摄中...')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.aPicture {
  box-sizing: border-box;
  width: 2400px;
  height: 2400px;
  position: relative;
  .map-bg {
    box-sizing: border-box;
    width: 2400px;
    height: 2400px;
  }
  .point-layer {
    box-sizing: border-box;
    position: absolute;
    left: attr(data-left);
    .point-icon {
      width: 50px;
      position: absolute;
      left: 50%;
      top: 10px;
      transform: translate(-50%, -100%);
    }
    .text {
      color: #ffffff;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      padding: 0px 20px;
      font-size: 22px;
      white-space: nowrap;
    }
  }
}
</style>